import { useTranslation } from "@equiem/web-ng-lib";
import { Button, Theme } from "@equiem/web-ng-lib";
import { random } from "lodash";
import Link from "next/link";
import React, { useContext } from "react";

interface Props {
  title?: string;
  className?: string;
  ctaReplacement?: JSX.Element;
  imageName?: string;
}

export const ContentNotAvailable: React.FC<Props> = ({
  title,
  ctaReplacement,
  className = "",
  imageName,
}) => {
  const { t } = useTranslation();

  const theme = useContext(Theme);
  const heading = title ?? t("main.pageNotAvailableAnymore");
  const image = imageName ?? `page-not-available-${random(1, 3)}`;

  return (
    <div className={`text-center m-5 ${className}`}>
      <h1 className="h5">{heading}</h1>
      <div className="pt-4 pb-5 na-content">
        {ctaReplacement != null ? (
          ctaReplacement
        ) : (
          <Link href="/" passHref>
            <Button variant="solid">{t("main.returnToHome")}</Button>
          </Link>
        )}
      </div>
      <img
        className="img-fluid hide-in-percy hero-img"
        src={`/images/${image}.png`}
        alt={heading}
      />
      <style jsx global>{`
        .hero-img {
          opacity: 50%;
        }
        @media screen and (min-width: ${theme.breakpoints.lg}px) {
          .hero-img {
            max-width: 50%;
          }
        }
        .na-content :global(a) {
          color: ${theme.colors.defaultLink};
        }
      `}</style>
    </div>
  );
};

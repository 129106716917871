import { formatters, useTranslation } from "@equiem/web-ng-lib";
import { Viewer } from "@equiem/web-ng-lib";
import React, { useContext } from "react";
import { Comment } from "./icons/Comment";
import { Envelope } from "./icons/Envelope";
import { ThumbsUp } from "./icons/ThumbsUp";

interface Props {
  size?: number;
  content: {
    likeCount: number;
    commentCount: number;
    rsvpCount?: number;
  };
}

export const InteractionCounts: React.FC<Props> = ({
  size = 16,
  content: { likeCount, commentCount, rsvpCount },
}) => {
  const { i18n } = useTranslation();

  const viewer = useContext(Viewer);

  return (
    <div className="text-muted" style={{ fontSize: `${size / 16}rem` }}>
      <span className="likes mr-3">
        <ThumbsUp size={size} />{" "}
        <span className="icon-label">
          {formatters.integer(likeCount, i18n.language)}
        </span>
      </span>
      {viewer?.permissions.readComments === true ? (
        <span className="comments mr-3">
          <Comment size={size} />{" "}
          <span className="icon-label">
            {formatters.integer(commentCount, i18n.language)}
          </span>
        </span>
      ) : null}
      {rsvpCount != null ? (
        <span className="rsvps mr-3">
          <Envelope size={size} />{" "}
          <span className="icon-label">
            {formatters.integer(rsvpCount, i18n.language)}
          </span>
        </span>
      ) : null}
    </div>
  );
};

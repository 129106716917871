import React from "react";

export const Comment: React.FC<{ size?: number }> = ({ size = 16 }) => (
  <svg width={ size } height={size} viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5014 1C6.60823 1 1 4.50524 1 8.81354C1 10.6448 2.02371 12.4069 3.88858 13.8081L3.03533 18.4068C3.01275 18.4476 3.0038 18.4618 2.99757 18.4766C2.98278 18.5275 2.9785 18.5412 2.97694 18.5551C2.97694 18.6082 2.97655 18.6216 2.9785 18.635C2.99368 18.6884 2.99446 18.6979 2.99874 18.7075C3.01859 18.7494 3.04389 18.7844 3.07503 18.8172C3.1268 18.8665 3.16417 18.8941 3.20815 18.9174C3.2498 18.9368 3.27199 18.9483 3.29768 18.957C3.36502 18.9746 3.37942 18.9784 3.39499 18.9817C3.44909 18.9926 3.5032 19 3.55769 19C3.61491 18.9995 3.66863 18.9937 3.7204 18.983L8.8495 16.0656C10.3294 16.4366 11.8922 16.6268 13.501 16.6268C20.3929 16.6268 26 13.1216 26 8.81327C26.0004 4.50524 20.3933 1 13.5014 1Z"
      fill="none"
      strokeWidth="2"
    />
  </svg>
)

import React, { ReactElement, useEffect, useState } from "react";

interface Props {
  url?: string | null;
  placeholder?: boolean;
  width?: string;
  height?: string;
  overlay?: ReactElement;
  rounded?: "top" | "full" | "left";
  className?: string;
  children?: React.ReactNode;
}

const blank = "data:image/svg+xml,%3Csvg viewBox='0 0 300 200' xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E";

export const CardImage: React.FC<Props> = ({ rounded, url, children, overlay, placeholder = false, width = "auto", height = "auto", className = ""}) => {
  const [loading, setLoading] = useState(true);
  const [src, setSrc] = useState(blank);

  const imageClass = rounded != null ? (rounded === "top" ? "rounded-top" : (rounded === "left" ? "rounded-left" : "rounded")) : "";

  useEffect(() => {
    if (placeholder) {
      return;
    }

    if (url != null && src !== url) {
      const image = new Image();
      image.onload = () => {
        setSrc(url);
        setLoading(false);
      };
      image.onerror = () => {
        setLoading(false);
      };
      image.src = url;
    }
  }, [placeholder, url]);

  return (
    <div className={`image ${loading ? "shimmer loading" : "loaded"} ${className}`}>
      {<img src={src} className={imageClass} />}
      {overlay != null ? <div className="overlay d-flex text-center justify-content-center align-items-center">{overlay}</div> : null}
      {children != null ? <div className="buttons">{children}</div> : null}
      <style jsx>{`
    .image {
      position: relative;
      flex-shrink: 0;
      width: ${width};
      height: ${height};
    }
    .image.loaded {
      background: #efefef;
    }
    .image img {
      width: 100%;
      height: auto;
    }
    .overlay {
      position: absolute;
      z-index: 1;
      background: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      color: #fff;
    }
    .image .buttons {
      position: absolute;
      bottom: 0;
      right: 10px;
      transform: translate(0, 30%);
      z-index: 2;
    }
    `}</style>
    </div>
  );
}

import React from "react";

export const Envelope: React.FC<{ size?: number }> = ({ size = 16 }) => (
  <svg width={size} height={size} viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      strokeWidth="0"
      d="M0.749237 0.117798L0.736053 0.136103H0.643768C0.33312 0.136134 0.0812981 0.398368 0.0812683 0.72186V0.994146L0.0505066 1.03533L0.0812683 1.0605V11.2472C0.0812981 11.5707 0.33312 11.8329 0.643768 11.833H17.4836C17.6332 11.8335 17.7768 11.7721 17.8828 11.6622C17.9887 11.5523 18.0483 11.4029 18.0483 11.2472V1.06508L18.0505 1.06279L18.0483 1.0605V0.72186C18.0483 0.566115 17.9887 0.416786 17.8828 0.306873C17.7768 0.196959 17.6332 0.135509 17.4836 0.136103H0.77121L0.749237 0.117798ZM2.19064 1.30762H15.9455L9.07248 6.98672L2.19064 1.30762ZM8.72531 8.19485L1.20407 1.98948V10.6614H16.9211V1.99405L9.42184 8.19485C9.21758 8.36252 8.92957 8.36252 8.72531 8.19485Z"
    />
  </svg>
)

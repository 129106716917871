import { EngulfingLink, Theme } from "@equiem/web-ng-lib";
import React, { MouseEventHandler, ReactElement, useContext } from "react";

interface Props {
  img?: ReactElement;
  title: string | ReactElement;
  href?: string;
  as?: string;
  target?: React.HTMLAttributeAnchorTarget | undefined;
  onClick?: MouseEventHandler;
  focusable?: boolean;
  className?: string;
  disabled?: boolean;
  orientation?: "portrait" | "landscape";
  // Applies in landscape mode only.
  titleLineClamp?: number;
  children?: React.ReactNode;
}

export const Card: React.FC<Props> = ({
  img,
  title,
  href,
  as,
  focusable = true,
  children,
  className = "",
  disabled = false,
  orientation = "portrait",
  titleLineClamp = 1,
  onClick,
  target,
}) => {
  const { breakpoints } = useContext(Theme);
  const titleClassName = `mb-1 heading text-dark active-after-shadow ${orientation === "portrait" ? "pb-3" : ""}`;

  return (
    <div className={`${className} ${orientation} card ${href != null || onClick != null ? "with-link" : ""}`}>
      <div className="content d-flex w-100 h-100 text-dark border rounded overflow-hidden">
        <div className="image">{img}</div>
        <div className="body p-3 d-flex flex-column" style={{ flexGrow: 1 }}>
          {
            href != null || onClick != null ? (
              <EngulfingLink
                href={href}
                as={as}
                target={target}
                onClick={onClick}
                focusable={focusable}
                className={titleClassName} orientation={orientation}
                lineClamp={titleLineClamp}
              >
                {title}
              </EngulfingLink>
            ) : (
                <div className={titleClassName} tabIndex={!focusable ? -1 : 0}>
                  {title}
                </div>
              )
          }
          {children}
        </div>
      </div>
      <style jsx>{`
      .card {
        position: relative;
        width: 100%;
        height: 100%;
        font-size: 0.9375em;
        opacity: ${disabled ? 0.5 : 1};
      }
      .content {
        flex-direction: column;
      }
      .card.with-link {
        cursor: pointer;
      }
      .card:hover {
        z-index: 1;
      }
      .card :global(.heading) {
        text-decoration: none;
        text-align: left;
        font-size: 1.0625rem;
        font-weight: 600;
      }
      .body {
        overflow: hidden;
      }
      @media (min-width: ${breakpoints.sm}px) {
        .landscape {
          height: 8.72rem; /* 13 / 1.5 */
        }
        .landscape .content {
          margin-top: auto;
          flex-direction: row;
        }
        .landscape .image {
          min-width: 13rem;
          width: 13rem;
        }
        .landscape .header {
          max-width: 100%;
        }
        .landscape .title {
          width: 100%;
          height: 100%;
          max-height: ${titleLineClamp * 1.45}rem;
          display: -webkit-box;
          -webkit-line-clamp: ${titleLineClamp};
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    `}
      </style>
    </div>
  );
}
